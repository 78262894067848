<div class="card h-100">
  <div class="card-body">
    <div class="d-flex justify-content-between align-items-center mb-2" *ngIf="displayHeaders">
      <h4 for="create-location-button" class="m-0">
        {{ treeTitle }}
      </h4>
      <ng-container id="create-location-button" *ngIf="!hideCreate">
        <button
          *abpPermission="'RegisterService.Locations.Create'"
          class="btn btn-sm btn-primary"
          (click)="onAddRootNode()"
        >
          <i class="fas fa-plus me-1"></i>
          {{ 'RegisterService::NewLocation' | abpLocalization }}
        </button>
      </ng-container>
    </div>
    <abp-tree
      [nodes]="nodes"
      [checkable]="checkable"
      [checkStrictly]="checkStrictly"
      [(checkedKeys)]="checkedKeys"
      [(expandedKeys)]="expandedKeys"
      [selectedNode]="selectedNode"
      [draggable]="false"
      (selectedNodeChange)="onSelectedNode($event)"
    >
      <ng-template abpTreeNodeTemplate let-node>
        <div
          class="node-container d-flex flex-row justify-content-between"
          [class.selected]="isNodeSelected(node)"
          (mouseover)="node.isHovered = true"
          (mouseleave)="node.isHovered = false"
        >
          {{ node.title }}
          <div *ngIf="(node.isHovered || node.origin.id === selectedNode?.id) && !readonly">
            <i
              *abpPermission="'RegisterService.Locations.Create'"
              class="fas fa-plus fa-lg ms-3"
              (click)="onAddSubNode(node, $event)"
            ></i>
            <i
              *abpPermission="'RegisterService.Locations.Delete'"
              class="fas fa-trash-alt fa-lg ms-2"
              (click)="onDelete(node, $event)"
            ></i>
          </div>
        </div>
      </ng-template>
    </abp-tree>
    <div class="card-body" *ngIf="!nodes?.length">
      <p class="text-muted">
        {{ 'RegisterService::NoLocations' | abpLocalization }}
      </p>
    </div>
  </div>
</div>
