<div class="card h-100">
  <div class="card-header d-flex justify-content-end align-items-center" *ngIf="!readonly">
    <div class="buttons-container d-flex gap-2" id="buttons-container">
      <button class="btn btn-sm btn-secondary" *ngIf="!isConsumable" (click)="toggleHidden($event)">
        <i [ngClass]="{ 'fa-eye': show, 'fa-eye-slash': !show }" class="fa"></i>
        {{
          show
            ? ('RegisterService::SeeHidden' | abpLocalization)
            : ('RegisterService::SeeNotHidden' | abpLocalization)
        }}
      </button>
      <button
        *abpPermission="
          isConsumable ? 'RegisterService.Consumables.Create' : 'RegisterService.Categories.Create'
        "
        class="btn btn-sm btn-primary"
        (click)="onAddRootNode()"
      >
        <i class="fas fa-plus me-1"></i>
        {{ createBtnText || 'RegisterService::NewCategory' | abpLocalization }}
      </button>
    </div>
  </div>

  <div class="card-body" [abpLoading]="isLoading" *ngIf="show">
    <abp-tree
      [nodes]="nodes"
      [(expandedKeys)]="expandedKeys"
      [selectedNode]="selectedNode"
      [draggable]="false"
      (selectedNodeChange)="onSelectedNode($event)"
    >
      <ng-template abpTreeNodeTemplate let-node>
        <div
          class="node-container d-flex"
          [style.background-color]="
            node.origin.id === selectedNode?.id
              ? ''
              : node.isHovered && node.origin.entity.color
              ? '#FFD8C2'
              : node.origin.entity.color
          "
          [class.selected]="node.origin.id === selectedNode?.id"
          [ngClass]="{ 'node-hidden': show === false }"
          (mouseover)="node.isHovered = true"
          (mouseleave)="node.isHovered = false"
        >
          <div
            class="relative w-0"
            *ngIf="!isConsumable && node.origin.entity.hasChildren && !node._children.length"
          >
            <i
              aria-hidden="true"
              (click)="toggle($event, node)"
              class="ng-star-inserted ant-tree-switcher absolute top-1 right-[30px]"
              ><svg
                width="15"
                height="15"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                fill-rule="evenodd"
                clip-rule="evenodd"
                class="ng-star-inserted"
              >
                <path
                  d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"
                ></path></svg
            ></i>
          </div>

          <i class="fas fa-folder fs-15px text-primary me-1" id="node-icon"></i>
          <div class="d-flex justify-content-between w-full truncate label-icon-container">
            <label
              for="node-icon"
              [ngClass]="{
                'w-full': !node.isHovered,
                'max-w-[15vw]': node.isHovered
              }"
              class="cursor-pointer transition-all duration-300 truncate"
            >
              {{ node.title }}
              <strong class="text-primary">
                {{ node.origin.entity.childCount ? '(' + node.origin.entity.childCount + ')' : '' }}
                {{ node.origin.entity.totalCount || '' }}
                {{ counts.length && !node.origin.entity.totalCount ? findCount(node.key) : '' }}
              </strong>
            </label>
            <div
              *ngIf="(window.innerWidth > 480 ? node.isHovered : true) && !readonly"
              class="d-flex align-items-center"
            >
              <i
                *abpPermission="'RegisterService.Categories.Hide' && isConsumable"
                [ngClass]="{ 'fa-eye': !show, 'fa-eye-slash': show }"
                class="fa fa-lg me-2"
                aria-hidden="true"
                [title]="!show ? 'show' : 'hide'"
                (click)="onToggleShow(node, $event)"
              ></i>
              <i
                class="fas fa-plus fa-lg me-2"
                *abpPermission="
                  isConsumable
                    ? 'RegisterService.Consumables.Create'
                    : 'RegisterService.Categories.Edit'
                "
                (click)="onAddSubNode(node, $event)"
              ></i>
              <div *ngIf="!node.origin.entity.referenceId" class="">
                <i
                  class="fas fa-trash-alt fa-lg"
                  *abpPermission="
                    isConsumable
                      ? 'RegisterService.Consumables.Delete'
                      : 'RegisterService.Categories.Delete'
                  "
                  (click)="onDelete(node, $event)"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </abp-tree>
    <p *ngIf="!isLoading && !nodes?.length" class="text-muted">
      {{ noDataText || 'RegisterService::NoCategories' | abpLocalization }}
    </p>
  </div>
  <div class="card-body" *ngIf="!show">
    <div *ngFor="let hiddenNode of data.items">
      <div
        class="node-container d-flex flex-row node-hidden cursor-pointer"
        (mouseover)="hiddenNode.isHovered = true"
        (mouseleave)="hiddenNode.isHovered = false"
      >
        {{ hiddenNode.name }}
        <div *ngIf="hiddenNode.isHovered" class="ms-auto d-flex align-items-center">
          <i
            class="fa fa-eye fa-lg ms-2"
            [title]="'show'"
            (click)="onToggleShow(hiddenNode, $event)"
          >
          </i>
          <div *ngIf="!hiddenNode.referenceId" class="ms-2">
            <i
              class="fas fa-trash-alt fa-lg"
              *abpPermission="'RegisterService.Categories.Delete'"
              (click)="onDelete(hiddenNode, $event)"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="!isLoading && !data.items?.length" class="text-muted">
      {{ 'RegisterService::NoCategories' | abpLocalization }}
    </p>
  </div>
</div>
