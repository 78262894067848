import {
  AfterViewChecked,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { finalize } from 'rxjs';
import { ConsumableService } from '@proxy/register-service/consumables/consumable.service';

import { LocationService } from '@proxy/register-service/locations';
import {
  AddConsumableCount,
  ConsumableDto,
  IConsumableCounts,
  IConsumeInLocation,
} from '@proxy/register-service/consumables';
import { ToasterService } from '@abp/ng.theme.shared';
import { AssetDetailsDto } from '@proxy/register-service/assets';

@Component({
  selector: 'app-consumables-select',
  templateUrl: './consumables-select.component.html',
  styleUrls: ['./consumables-select.component.scss'],
})
export class ConsumablesSelectComponent implements OnInit, AfterViewChecked {
  @Input() isModalOpen = false;
  @Input() forEvents = false;
  @Input() locationId: string;
  @Input() consumable: ConsumableDto;
  @Input() asset: AssetDetailsDto;
  @Input() pinOrKeyringPassword: string;
  @Input() isQtyAssets: boolean = false;

  @Output() isModalOpenChange = new EventEmitter<boolean>();
  @Output() consumableChange = new EventEmitter<ConsumableDto | null>();
  @Output() submitAction: EventEmitter<AddConsumableCount[]> = new EventEmitter();
  @ViewChildren('categoryName') textareas: QueryList<ElementRef<HTMLTextAreaElement>>;

  isLoading = false;
  openLocationModal = false;
  data: IConsumableCounts = null;
  currentData: IConsumableCounts = null;

  constructor(
    public readonly service: ConsumableService,
    private toasterService: ToasterService,
    public readonly locationsService: LocationService
  ) {}

  private adjustTextareaHeights() {
    const singleLineHeight = 44.5;
    const maxCharactersInOneLine = 34;

    this.textareas.forEach(textarea => {
      const element = textarea.nativeElement;
      const content = element.value.trim();
      element.style.height = 'auto';

      if (content.length <= maxCharactersInOneLine && element.scrollHeight <= 68) {
        element.style.height = `${singleLineHeight}px`;
      } else {
        element.style.height = `${element.scrollHeight}px`;
      }
    });
  }

  ngAfterViewChecked() {
    this.adjustTextareaHeights();
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.service
      .getCount(this.consumable.id, {
        pinOrKeyringPassword: this.pinOrKeyringPassword,
        assetId: this.asset?.id,
        isQtyAssets: this.isQtyAssets,
      })
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(data => {
        this.data = data;
        const locations = data.countsByLocation?.map(e => ({ ...e, count: null })) || null;

        const countsByLocation = this.locationId
          ? locations.filter(e => e.location.id === this.locationId)
          : locations;
        this.currentData = { ...data, countsByLocation };
      });
  }

  onChangeCount(event: any, id: string, max: number): void {
    const newValue = +event.target.value;
    const item = this.currentData.countsByLocation.find(e => e.location.id === id);

    if (!item) return;

    if (newValue > max || newValue < 0) {
      item.count = item.count === 0 ? null : 0;
      const message =
        newValue < 0
          ? 'Value can not be negative'
          : `Value exceeds the maximum allowed limit of ${max}`;

      this.toasterService.info(message);
    } else {
      item.count = newValue;
    }
  }

  getDiff(id: string, count: number) {
    const item = this.data.countsByLocation.find(e => e.location.id === id);
    return { count: item.count || 0, diff: item ? item.count - count : 0 };
  }

  getTotal() {
    return this.currentData?.countsByLocation
      ? this.currentData.countsByLocation.reduce((sum, item) => sum + item.count, 0)
      : 0;
  }

  onSelect() {
    if (this.forEvents) {
      const counts: AddConsumableCount[] = this.currentData.countsByLocation
        .filter(e => e.count)
        .map(({ count, location }) => ({
          consumableId: this.consumable.id,
          count,
          locationId: location.id,
          name: this.consumable.name,
          locationName: location.name,
        }));
      this.submitAction.emit(counts);
    } else {
      this.openLocationModal = true;
    }
  }

  onLocationSelect({ id, isTransfer }: { id: string; isTransfer: boolean }) {
    this.isLoading = true;
    const selectedItems = this.currentData.countsByLocation.filter(e => e.count);
    const body: IConsumeInLocation = {
      toLocationId: id,
      consumableId: this.consumable.id,
      isQtyAssets: this.isQtyAssets,
      countByLocations: selectedItems.map(({ location, count }) => ({
        getFromLocationId: location.id,
        count,
      })),
    };

    const request = isTransfer
      ? this.service.transferToLocation(body)
      : this.service.consumeInLocation(body);

    request
      .pipe(
        finalize(() => {
          this.openLocationModal = false;
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          this.isModalOpen = false;
          this.submitAction.emit();
          this.toasterService.success('ServicingService::SuccessfullySaved');
        },
        error: error => this.service.handleErrorResponse(error),
      });
  }

  onCancel() {
    this.isModalOpenChange.emit(false);
    this.consumableChange.emit(null);
  }
}
