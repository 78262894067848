<app-categories-tree
  [data]="data"
  [readonly]="readonly"
  [isConsumable]="true"
  [childsOnly]="childsOnly"
  [createBtnText]="createBtnText"
  [(expandedKeys)]="expandedKeys"
  [noDataText]="noDataText"
  [selectedNode]="selectedCategory"
  (addRootNode)="addRoot.emit()"
  (addSubNode)="addSubUnit.emit($event)"
  (deleteNode)="onDelete($event)"
  (selectedNodeChange)="selectedNodeChange.emit($event)"
>
</app-categories-tree>
