import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import type {
  GetConsumableInput,
  ConsumableCreateDto,
  ConsumableDto,
  IConsumableCounts,
  IConsumeInLocation,
} from './models';
import { ConfirmationService } from '@abp/ng.theme.shared';

@Injectable({
  providedIn: 'root',
})
export class ConsumableService {
  apiName = 'RegisterService';
  urlPrefix = '/api/register/consumable';

  create = (input: ConsumableCreateDto) => {
    var formdata = new FormData();
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        const val = input[key];
        formdata.append(key, val != null ? val : '');
      }
    }

    return this.restService.request<any, ConsumableDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: formdata,
      },
      { apiName: this.apiName }
    );
  };

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, ConsumableDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getCount = (
    id: string,
    input: { pinOrKeyringPassword: string; assetId?: string; isQtyAssets?: boolean }
  ) =>
    this.restService.request<any, IConsumableCounts>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/getConsumablesCount`,
        params: {
          pinOrKeyringPassword: input.pinOrKeyringPassword,
          assetId: input.assetId,
          isQtyAssets: input.isQtyAssets,
        },
      },
      { apiName: this.apiName }
    );

  consumeInLocation = (body: IConsumeInLocation) => {
    return this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/consumeInLocation`,
        body,
      },
      { apiName: this.apiName }
    );
  };

  transferToLocation = (body: IConsumeInLocation) => {
    return this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/transferToLocation`,
        body,
      },
      { apiName: this.apiName }
    );
  };

  getPicture = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/picture`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  deletePicture = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/picture`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetConsumableInput) =>
    this.restService.request<any, PagedResultDto<ConsumableDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          term: input.term,
          name: input.name,
          getCounts: input.getCounts,
          show: input.show,
          isQtyAssets: input.isQtyAssets,
          picture: input.picture,
          takeAvailableOnes: input.takeAvailableOnes,
          parentId: input.parentId,
          pinOrKeyringPassword: input.pinOrKeyringPassword,
          locationId: input.locationId,
          sorting: input.sorting,
          assetId: input.assetId,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: ConsumableDto) => {
    return this.restService.request<any, ConsumableDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: { ...input, mode: undefined },
      },
      { apiName: this.apiName }
    );
  };

  updatePicture = (id: string, input: FormData) => {
    return this.restService.request<any, any>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/picture`,
        body: input,
      },
      { apiName: this.apiName }
    );
  };

  deactivate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/deactivate`,
      },
      { apiName: this.apiName }
    );

  activate = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/activate`,
      },
      { apiName: this.apiName }
    );

  handleErrorResponse(error: any): void {
    this.confirmation.info(error.error.error.message, 'Consumable Creation', {
      cancelText: 'OK',
      hideYesBtn: true,
    });
    return;
  }

  constructor(private restService: RestService, public confirmation: ConfirmationService) {}
}
