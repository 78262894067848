<div class="dropdown custom-multi-select" ngbDropdown [autoClose]="'outside'">
  <button
    type="button"
    class="btn btn-light opacity-100 dropdown-toggle text-[#325168] w-full"
    ngbDropdownToggle
    [attr.aria-expanded]="dropdownOpen ? 'true' : 'false'"
  >
    {{ getSelectedItems() || 'ServicingService::SelectOptions' | abpLocalization }}
  </button>
  <div ngbDropdownMenu class="dropdown-menu w-full">
    <div *ngFor="let option of options" class="dropdown-item flex cursor-pointer px-2 gap-2">
      <input
        type="checkbox"
        [id]="option.id"
        class="form-check-input"
        [checked]="isOptionSelected(option)"
        (change)="onOptionChange(option)"
      />
      <label class="w-full" [for]="option.id">{{ option.label }}</label>
    </div>
  </div>
</div>
