import { mapEnumToOptions } from '@abp/ng.core';

export enum FrequencyType {
  None = 'NONE',
  Hour = 'HOUR',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Semester = 'SEMESTER',
  Year = 'YEAR',
  Kilometers = 'KILOMETERS',
  Miles = 'MILES',
}

export const frequencyTypeOptions = mapEnumToOptions(FrequencyType);
