import { Injectable } from '@angular/core';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import type {
  GetInspectionsInput,
  InspectionCreateDto,
  InspectionDto,
  InspectionUpdateDto,
  InspectionDetailsDto,
} from './models';
import { serialize } from 'object-to-formdata';
import { FrequencyType } from '@proxy/servicing-service/inspection-types';

@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  apiName = 'ServicingService';
  urlPrefix = '/api/servicing/inspections';

  create = (input: InspectionCreateDto) => {
    const formdata = serialize(input, { indices: true, dotsForObjectNotation: true });
    return this.restService.request<any, InspectionDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: formdata,
      },
      { apiName: this.apiName }
    );
  };

  bulkUpload = (input: any[]) => {
    return this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/bulk-upload`,
        body: input,
      },
      { apiName: this.apiName }
    );
  };

  approve = (id: string) =>
    this.restService.request<any, InspectionDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/approve`,
      },
      { apiName: this.apiName }
    );

  approveBatch = (ids: string[]) =>
    this.restService.request<any, InspectionDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/approve`,
        body: {
          ids,
        },
      },
      { apiName: this.apiName }
    );

  stop = (id: string) =>
    this.restService.request<any, InspectionDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/archive`,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  deleteBatch = (ids: string[]) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}`,
        body: {
          ids,
        },
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, InspectionDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetInspectionsInput, isPageTemporary: boolean = false) =>
    this.restService.request<any, PagedResultDto<InspectionDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}${isPageTemporary ? '/guest' : ''}`,
        params: {
          approvedOnMax: input.approvedOnMax,
          approvedOnMin: input.approvedOnMin,
          approverId: input.approverId,
          assetCode: input.assetCode,
          assetId: input.assetId,
          isOverdue: input.isOverdue,
          assetPrimaryID: input.assetPrimaryID,
          assetSecondaryID: input.assetSecondaryID,
          categoryId: input.categoryId,
          categoryName: input.categoryName,
          categoryNickname: input.categoryNickname,
          categoryPath: input.categoryPath,
          doneOnExact: input.doneOnExact,
          doneOnMax: input.doneOnMax,
          doneOnMin: input.doneOnMin,
          dueOnExact: input.dueOnExact,
          dueOnMax: input.dueOnMax,
          dueOnMin: input.dueOnMin,
          inspectorId: input.inspectorId,
          isActive: input.isActive,
          isGroup: input.isGroup,
          isLive: input.isLive,
          isHistorical: input.isHistorical,
          jobNumber: input.jobNumber,
          targetLocationId: input.targetLocationId,
          locationPath: input.locationPath,
          maxResultCount: input.maxResultCount,
          notes: input.notes,
          poNumber: input.poNumber,
          reportNumber: input.reportNumber,
          result: input.result,
          skipCount: input.skipCount,
          sorting: input.sorting,
          state: input.state,
          statusId: input.statusId,
          eventType: input.eventType,
          term: input.term,
          typeId: input.typeId,
        },
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string) =>
    this.restService.request<any, InspectionDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/details`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: InspectionUpdateDto) =>
    this.restService.request<any, InspectionDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  calculateDueDate = (doneOn: Date, interval: number, frequency: FrequencyType): Date => {
    const dueOn = new Date(doneOn.getTime());
    if (frequency === FrequencyType.Day) dueOn.setUTCDate(doneOn.getUTCDate() + interval);
    if (frequency === FrequencyType.Hour) dueOn.setHours(doneOn.getUTCHours() + interval);
    if (frequency === FrequencyType.Week) {
      dueOn.setUTCDate(doneOn.getUTCDate() + interval * 7);
    }
    if (frequency === FrequencyType.Month) dueOn.setUTCMonth(doneOn.getUTCMonth() + interval);
    if (frequency === FrequencyType.Year) dueOn.setUTCFullYear(doneOn.getUTCFullYear() + interval);
    if (frequency === FrequencyType.Quarter) dueOn.setUTCMonth(doneOn.getUTCMonth() + interval * 3);
    if (frequency === FrequencyType.Semester)
      dueOn.setUTCMonth(doneOn.getUTCMonth() + interval * 6);

    return dueOn;
  };

  calculateDueLimit = (doneOn: number, interval: number, frequency: FrequencyType): number => {
    let dueOn = doneOn;
    if (frequency === FrequencyType.Kilometers || frequency === FrequencyType.Miles)
      dueOn += interval;

    return dueOn;
  };

  unapprove = (id: string) =>
    this.restService.request<any, InspectionDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/unapprove`,
      },
      { apiName: this.apiName }
    );

  constructor(private restService: RestService) {}
}
