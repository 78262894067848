<div class="row">
  <div class="col">
    <div class="card">
      <iframe
        *ngIf="!assetDetails || showDashboard"
        [src]="trackItIframeUrl"
        id="trackit-iframe"
        width="100%"
        height="600"
        frameborder="0"
      ></iframe>
      <div class="card-body" *ngIf="showDashboard">
        <div class="selector-explanation-wrapper my-3">
          <div class="w-10">
            <h3 class="mb-2">{{ 'RegisterService::SelectTrackers' | abpLocalization }}</h3>
            <select
              class="form-select form-control tracker-selector"
              [(ngModel)]="selectedTracker"
              (change)="onSelectChange()"
            >
              <option *ngFor="let tracker of trackers" [ngValue]="tracker">
                {{ tracker.name }}
              </option>
            </select>
          </div>
          <div *ngIf="showDashboardInfo">
            <div class="card-border upper-boxes center">
              <h4>{{ 'RegisterService::LastTrip' | abpLocalization }}</h4>
              <h5>{{ dashboardInfo?.lastTripDate }}</h5>
            </div>
          </div>
          <div *ngIf="showDashboardInfo">
            <div class="card-border upper-boxes center">
              <h4>{{ 'RegisterService::LifeToDate' | abpLocalization }}</h4>
              <h5>{{ dashboardInfo?.lifeToDate }}</h5>
            </div>
          </div>
          <div *ngIf="showDashboardInfo" class="d-flex levels">
            <div>
              <div class="center color-info">
                <div id="red-rectangle" class="rectangle red"></div>
                <label for="red-rectangle">0-40% Low Level</label>
              </div>
              <div class="center color-info">
                <div id="yellow-rectangle" class="rectangle yellow"></div>
                <label for="yellow-rectangle">41-70% Mid Level</label>
              </div>
              <div class="center color-info">
                <div id="green-rectangle" class="rectangle green"></div>
                <label for="green-rectangle">71-100% High Level</label>
              </div>
            </div>
          </div>
        </div>
        <div class="charts mb-3" *ngIf="showDashboardInfo">
          <div id="chart1" class="chart-container">
            <div class="center">
              <h3>{{ 'RegisterService::CurrentDay' | abpLocalization }}</h3>
            </div>
            <apx-chart
              class="center"
              [series]="chartOptionsDaily.series"
              [chart]="chartOptionsDaily.chart"
              [plotOptions]="chartOptionsDaily.plotOptions"
              [labels]="chartOptionsDaily.labels"
              [stroke]="chartOptionsDaily.stroke"
              [fill]="chartOptionsDaily.fill"
              [responsive]="chartOptionsDaily.responsive"
            ></apx-chart>
            <div class="mb-3">
              <div class="center">
                <p class="p-0 m-0">{{ helpers.normalizeTextDate(today) }}</p>
              </div>
              <div class="center card-border">
                <h4>{{ 'RegisterService::CurrentDay' | abpLocalization }}</h4>
                <h5>{{ dashboardInfo?.dailyRunDateTime }}</h5>
              </div>
            </div>
          </div>
          <div id="chart2" class="chart-container">
            <div class="center">
              <h3>{{ 'RegisterService::CurrentWeek' | abpLocalization }}</h3>
            </div>
            <apx-chart
              class="center"
              [series]="chartOptionsWeekly.series"
              [chart]="chartOptionsWeekly.chart"
              [plotOptions]="chartOptionsWeekly.plotOptions"
              [labels]="chartOptionsWeekly.labels"
              [stroke]="chartOptionsWeekly.stroke"
              [fill]="chartOptionsWeekly.fill"
              [responsive]="chartOptionsDaily.responsive"
            ></apx-chart>
            <div class="mb-3">
              <div class="center">
                <p class="p-0 m-0">
                  {{ weekStartDay + ' - ' + helpers.normalizeTextDate(this.today) }}
                </p>
              </div>
              <div class="center card-border">
                <h4>{{ 'RegisterService::CurrentWeek' | abpLocalization }}</h4>
                <h5>{{ dashboardInfo?.weeklyRunDateTime }}</h5>
              </div>
            </div>
          </div>
          <div id="chart3" class="chart-container">
            <div class="center">
              <h3>{{ 'RegisterService::CurrentMonth' | abpLocalization }}</h3>
            </div>
            <apx-chart
              class="center"
              [series]="chartOptionsMonthly.series"
              [chart]="chartOptionsMonthly.chart"
              [plotOptions]="chartOptionsMonthly.plotOptions"
              [labels]="chartOptionsMonthly.labels"
              [stroke]="chartOptionsMonthly.stroke"
              [fill]="chartOptionsMonthly.fill"
              [responsive]="chartOptionsDaily.responsive"
            ></apx-chart>
            <div class="mb-3">
              <div class="center">
                <p class="p-0 m-0">
                  {{ monthStartDay + ' - ' + helpers.normalizeTextDate(this.today) }}
                </p>
              </div>
              <div class="center card-border">
                <h4>{{ 'RegisterService::CurrentMonth' | abpLocalization }}</h4>
                <h5>{{ dashboardInfo?.monthlyRunDateTime }}</h5>
              </div>
            </div>
          </div>
          <div id="chart4" class="chart-container">
            <div class="center">
              <h3>{{ 'RegisterService::CurrentYear' | abpLocalization }}</h3>
            </div>
            <apx-chart
              class="center"
              [series]="chartOptionsYearly.series"
              [chart]="chartOptionsYearly.chart"
              [plotOptions]="chartOptionsYearly.plotOptions"
              [labels]="chartOptionsYearly.labels"
              [stroke]="chartOptionsYearly.stroke"
              [fill]="chartOptionsYearly.fill"
              [responsive]="chartOptionsDaily.responsive"
            ></apx-chart>
            <div class="mb-3">
              <div class="center">
                <p class="p-0 m-0">
                  {{ yearStartDay + ' - ' + helpers.normalizeTextDate(this.today) }}
                </p>
              </div>
              <div class="center card-border">
                <h4>{{ 'RegisterService::CurrentYear' | abpLocalization }}</h4>
                <h5>{{ dashboardInfo?.yearlyRunDateTime }}</h5>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showDashboardInfo">
          <div class="mb-3 datepicker-container">
            <div class="label-description-container">
              <label for="lastTripDatePicker" class="lastTripLabel">
                {{ 'RegisterService::LastTrip' | abpLocalization }}
              </label>
              <p>
                {{ 'RegisterService::ChooseTimeForMapNote' | abpLocalization }}
              </p>
            </div>
            <div id="lastTripDatePicker" class="date-filter-container">
              <div class="startTripDatepicker">
                <label class="form-label" for="betweenDate">
                  {{ 'RegisterService::StartDate' | abpLocalization }}
                </label>
                <div class="input-group mb-3">
                  <input
                    id="startTripDate"
                    class="form-control"
                    #startTripDateInput
                    #startTripDateDatepicker="ngbDatepicker"
                    name="startTripDate"
                    ngbDatepicker
                    container="body"
                    (click)="startTripDateDatepicker.toggle()"
                    (keyup.space)="startTripDateDatepicker.toggle()"
                    [(ngModel)]="dashboardInfoFilters.startTripDate"
                    [ngModelOptions]="{ standalone: true }"
                    (dateSelect)="onDateSelect($event, 'startTripDate')"
                  />
                  <div *ngIf="startTripDateInput.value" class="input-group-append">
                    <button
                      class="btn btn-link px-2"
                      type="button"
                      [title]="'AbpUi::Clear' | abpLocalization"
                      (click)="
                        startTripDateDatepicker.writeValue(null);
                        startTripDateDatepicker.manualDateChange(null, true);
                        onDateSelect(null, 'startTripDate')
                      "
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <ngb-timepicker
                  *ngIf="dashboardInfoFilters.startTripDate"
                  [(ngModel)]="startTime"
                  [seconds]="true"
                  [spinners]="false"
                  (ngModelChange)="onStartTimeChange($event)"
                />
              </div>
              <div class="endTripDatepicker">
                <label class="form-label" for="betweenDate">
                  {{ 'RegisterService::EndDate' | abpLocalization }}
                </label>
                <div class="input-group mb-3">
                  <input
                    id="endTripDate"
                    class="form-control"
                    #endTripDateInput
                    #endTripDateDatepicker="ngbDatepicker"
                    name="endTripDate"
                    ngbDatepicker
                    container="body"
                    (click)="endTripDateDatepicker.toggle()"
                    (keyup.space)="endTripDateDatepicker.toggle()"
                    [(ngModel)]="dashboardInfoFilters.endTripDate"
                    [ngModelOptions]="{ standalone: true }"
                    (dateSelect)="onDateSelect($event, 'endTripDate')"
                  />
                  <div *ngIf="endTripDateInput.value" class="input-group-append">
                    <button
                      class="btn btn-link px-2"
                      type="button"
                      [title]="'AbpUi::Clear' | abpLocalization"
                      (click)="
                        endTripDateDatepicker.writeValue(null);
                        endTripDateDatepicker.manualDateChange(null, true);
                        onDateSelect(null, 'endTripDate')
                      "
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
                <ngb-timepicker
                  *ngIf="dashboardInfoFilters.endTripDate"
                  [(ngModel)]="endTime"
                  [seconds]="true"
                  [spinners]="false"
                  (ngModelChange)="onEndTimeChange($event)"
                />
              </div>
              <div class="submit-button mb-3">
                <button type="button" class="btn btn-primary" (click)="this.onSelectChange()">
                  <span>{{ 'AbpUi::Submit' | abpLocalization }}</span>
                </button>
              </div>
            </div>
          </div>
          <!-- <div class="mb-3" *ngIf="latlng.length !== 0">
            <agm-map
              *ngIf="latlng.length !== 0"
              #agmMap
              [latitude]="latlng[0][0]"
              [longitude]="latlng[0][1]"
              [zoom]="18"
              [streetViewControl]="false"
              [mapTypeControl]="true"
              [scrollwheel]="false"
            >
              <agm-marker
                *ngFor="let coordinate of startEndMarker; let i = index"
                [latitude]="coordinate[0]"
                [longitude]="coordinate[1]"
                [title]="i === 0 ? 'Start' : 'Stop'"
                [iconUrl]="i === 0 ? iconStart : iconEnd"
              ></agm-marker>
              <agm-polyline [strokeColor]="'#F0574D'" [strokeWeight]="3" [visible]="true">
                <agm-polyline-point
                  *ngFor="let coordinate of latlng; let i = index"
                  [latitude]="coordinate[0]"
                  [longitude]="coordinate[1]"
                >
                </agm-polyline-point>
              </agm-polyline>
            </agm-map>
          </div> -->
          <div *ngIf="latlng.length === 0">
            <label>{{ 'RegisterService::NoMapData' | abpLocalization }}</label>
          </div>
        </div>
        <div *ngIf="!showDashboardInfo" class="tracker-selector">
          <label for="">{{ 'RegisterService::NoTrackerData' | abpLocalization }}</label>
        </div>
      </div>
      <div *ngIf="!showDashboard">
        <label for="">{{ 'RegisterService::NoTrackers' | abpLocalization }}</label>
      </div>
    </div>
  </div>
</div>
