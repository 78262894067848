import { Environment } from '@abp/ng.core';

// const baseUrl = window.location.origin;
// const baseApi = 'api.siteplant.io';
// const baseAuth = 'auth.siteplant.io';
const proto = window.location.protocol;
const baseUrl = `${proto}//{0}.app.siteplant.io`;
const baseApi = `${proto}//{0}.api.siteplant.io`;
const authUrl = `${proto}//{0}.auth.siteplant.io/`;
const oAuthConfig = {
  issuer: authUrl,
  redirectUri: baseUrl,
  clientId: 'SitePlant_App',
  responseType: 'code',
  scope: [
    'offline_access',
    'openid',
    'profile',
    'email',
    'phone',
    'AccountService',
    'IdentityService',
    'AdministrationService',
    'SaasService',
    'RegisterService',
    'ServicingService',
    'CertReportService',
    'QueueService',
  ].join(' '),
  requireHttps: true,
  strictDiscoveryDocumentValidation: true,
  skipIssuerCheck: true,
};

export const environment = {
  production: true,
  application: {
    baseUrl,
    name: 'SitePlant',
  },
  flatfileEnvironmentId: 'us_env_sniYOJQg',
  flatfilePublishableKey: 'pk_e977b75a548d4dd1a8c130a5bb0a2726',
  token: 'HgUMpl8lLLsQIJ220a0371eRRPbGw3H27sCkBAuc9MSImIyaIo3Fe5nTLmn7fVXb',
  oAuthConfig,
  trackItUrl: 'https://trackit.siteplant.com',
  googleMaps: 'AIzaSyDAJlkiJWz_rf0Vel-CwkeQvcIh0JcBJ-k',
  apis: {
    default: {
      url: baseApi,
      rootNamespace: 'SitePlant',
    },
    // AbpAccountPublic: {
    //   url: oAuthConfig.issuer,
    //   rootNamespace: 'AbpAccountPublic',
    // },
  },
  // remoteEnv: {
  //   url: '/getEnvConfig',
  //   mergeStrategy: 'deepmerge'
  // }
} as Environment;
