import { LatLngLiteral } from '@agm/core';
import { Component, Input, OnInit } from '@angular/core';
import {
  AssetDetailsDto,
  AssetService,
  ChartOptions,
  DashboardInfoDto,
  TrackerDto,
} from '@proxy/register-service/assets';
import { environment } from 'src/environments/environment';
import Helpers from '@/shared/utils/helpers';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-asset-trackers',
  templateUrl: './asset-trackers.component.html',
  styleUrls: ['./asset-trackers.component.scss'],
})
export class AssetTrackersComponent implements OnInit {
  @Input() assetDetails: AssetDetailsDto;
  chartOptionsDaily: Partial<ChartOptions>;
  chartOptionsMonthly: Partial<ChartOptions>;
  chartOptionsWeekly: Partial<ChartOptions>;
  chartOptionsYearly: Partial<ChartOptions>;
  dashboardInfo: DashboardInfoDto;
  dashboardInfoFilters: { startTripDate: string; endTripDate: string } = {
    startTripDate: '',
    endTripDate: '',
  };
  endTime = null;
  iconEnd = {
    url: 'assets/images/endMarker.svg',
    scaledSize: {
      width: 60,
      height: 60,
    },
  };
  iconStart = {
    url: 'assets/images/startMarker.svg',
    scaledSize: {
      width: 60,
      height: 60,
    },
  };
  latlng: number[][] = [];
  map: any;
  monthStartDay: string = '';
  selectedTracker: TrackerDto;
  showDashboard: boolean;
  showDashboardInfo: boolean;
  startEndMarker: number[][] = [];
  startTime = null;
  helpers = Helpers;
  today: Date = new Date();
  trackers: TrackerDto[] = [];
  weekStartDay: string = '';
  yearStartDay: string = '';
  trackItIframeUrl: SafeResourceUrl | null = null;
  constructor(private assetService: AssetService, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.weekStartDay = this.helpers.normalizeTextDate(
      new Date(new Date().setDate(this.today.getDate() - 7))
    );

    this.monthStartDay = this.helpers.normalizeTextDate(
      new Date(new Date().setMonth(this.today.getMonth() - 1))
    );

    this.yearStartDay = this.helpers.normalizeTextDate(
      new Date(new Date().setFullYear(this.today.getFullYear() - 1))
    );

    this.getTrackersLookup();
  }

  initializeIframeCommunication() {
    this.trackItIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.trackItUrl);

    setTimeout(() => {
      const iframe = document.getElementById('trackit-iframe') as HTMLIFrameElement;

      if (!iframe || !iframe.contentWindow) {
        console.error('Iframe not found or not loaded.');
        return;
      }

      const sanitizedApiUrl = environment.apis.default.url.replace('{0}.', '');
      const access_token = localStorage.getItem('access_token');
      const message = {
        access_token,
        url: sanitizedApiUrl,
        origin: 'siteplant',
        hideList: !!this.assetDetails,
        activeDevicesID: this.assetDetails ? this.trackers.map(e => e.id) : [],
      };

      const maxRetries = 10;
      let attempts = 0;

      const sendMessage = () => {
        if (attempts >= maxRetries) {
          console.error('Failed to communicate with the iframe after multiple attempts.');
          return;
        }

        attempts++;
        console.log(`Sending message attempt ${attempts}`);

        try {
          iframe.contentWindow.postMessage(message, environment.trackItUrl);
        } catch (error) {
          console.error('Error sending postMessage:', error);
        }

        // Retry if no confirmation is received
        setTimeout(sendMessage, 500);
      };

      sendMessage();

      window.addEventListener('message', event => {
        if (event.origin === new URL(environment.trackItUrl).origin) {
          console.log('Message received from iframe:', event.data);

          if (event.data.confirmation) {
            console.log('Iframe confirmed receipt of message.');
            // Stop further retries
            attempts = maxRetries;
          }
        }
      });
    }, 500);
  }

  onDateSelect(
    event: { year: number; month: number; day: number },
    specifiedFilter: 'startTripDate' | 'endTripDate'
  ) {
    const isStartFilter = specifiedFilter === 'startTripDate';
    const time = isStartFilter ? this.startTime : this.endTime;
    const correctDate = event
      ? this.createLocalDate(
          event.year,
          event.month - 1,
          event.day,
          time?.hour ?? (isStartFilter ? 0 : 22),
          time?.minute ?? (isStartFilter ? 0 : 59),
          time?.second ?? (isStartFilter ? 0 : 59)
        ).toISOString()
      : '';

    this.dashboardInfoFilters[specifiedFilter] = correctDate;
    this.onSelectChange();
  }

  createLocalDate(
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number
  ): Date {
    const date = new Date(year, month, day, hour, minute, second);
    const offset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() - offset);
  }

  getTrackersLookup() {
    const input = {
      id: this.assetDetails?.id || null,
      includeAllDevices: !this.assetDetails,
    };

    this.assetService.getTrackersLookup(input.id, input.includeAllDevices).subscribe({
      next: data => {
        this.trackers = data;
        if (this.trackers && this.trackers.length > 0) {
          this.showDashboard = true;
          this.selectedTracker = this.trackers[0];
          this.onSelectChange();
        } else {
          this.showDashboard = false;
        }
      },
    });
    this.initializeIframeCommunication();
  }

  onSelectChange() {
    this.assetService
      .getDashboardInfo({
        id: this.selectedTracker.id,
        startTripDate: this.dashboardInfoFilters.startTripDate,
        endTripDate: this.dashboardInfoFilters.endTripDate,
      })
      .subscribe({
        next: data => {
          this.dashboardInfo = data;
          if (this.dashboardInfo) {
            this.showDashboardInfo = true;
            this.chartOptionsDaily = this.getChartOptions(this.dashboardInfo.dailyRunTime);
            this.chartOptionsWeekly = this.getChartOptions(this.dashboardInfo.weeklyRunTime);
            this.chartOptionsMonthly = this.getChartOptions(this.dashboardInfo.monthlyRunTime);
            this.chartOptionsYearly = this.getChartOptions(this.dashboardInfo.yearlyRunTime);
            // this.setPointsData();
          } else {
            this.showDashboardInfo = false;
          }
        },
      });
  }

  onStartTimeChange(event: { hour: number; minute: number; second: number }) {
    this.startTime = event;
    this.updateDateWithTime('startTripDate', this.startTime);
  }

  onEndTimeChange(event: { hour: number; minute: number; second: number }) {
    this.endTime = event;
    this.updateDateWithTime('endTripDate', this.endTime);
  }

  updateDateWithTime(
    specifiedFilter: 'startTripDate' | 'endTripDate',
    time: { hour: number; minute: number; second: number }
  ) {
    const date = new Date(this.dashboardInfoFilters[specifiedFilter]);
    if (!isNaN(date.getTime())) {
      const localDate = this.createLocalDate(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        time?.hour,
        time?.minute,
        time?.second
      );
      this.dashboardInfoFilters[specifiedFilter] = localDate.toISOString();
    }
  }

  setPointsData() {
    this.latlng = [];
    this.startEndMarker = [];
    this.dashboardInfo.points.forEach(point => {
      this.latlng.push([point.latitude, point.longitude]);
    });

    this.startEndMarker.push(this.latlng[0]);
    this.startEndMarker.push(this.latlng[this.latlng.length - 1]);
  }

  getChartOptions(value: number): ChartOptions {
    return {
      series: [value],
      chart: {
        height: 350,
        type: 'radialBar',
        width: 350,
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          hollow: {
            margin: 0,
            size: '70%',
            background: '#fff',
            image: undefined,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: '#fff',
            strokeWidth: '95%',
            margin: 0,
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -20,
              show: true,
              color: '#888',
              fontSize: '17px',
            },
            value: {
              offsetY: -1,
              color: '#111',
              fontSize: '36px',
              show: true,
            },
          },
        },
      },
      fill: {
        colors: [
          function ({ value, seriesIndex, w }) {
            if (value <= 40) {
              return '#e91d2a';
            } else if (value > 40 && value <= 70) {
              return '#f26822';
            } else {
              return '#329932';
            }
          },
        ],
      },
      stroke: {
        lineCap: 'round',
      },
      labels: ['Percent'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 250,
              width: 250,
            },
            plotOptions: {
              radialBar: {
                dataLabels: {
                  name: {
                    fontSize: '12px',
                  },
                  value: {
                    fontSize: '20px',
                  },
                },
              },
            },
          },
        },
      ],
    };
  }
}
