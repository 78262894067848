import { of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListService, PagedResultDto } from '@abp/ng.core';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import type { ConsumableDto } from '@proxy/register-service/consumables/models';
import { ConsumableTreeNode } from '@/consumables/models/consumable-tree-node.model';
import { ConsumableService } from '@proxy/register-service/consumables/consumable.service';

@Component({
  selector: 'app-consumables-tree',
  templateUrl: './consumables-tree.component.html',
  styleUrls: ['./consumables-tree.component.scss'],
})
export class ConsumablesTreeComponent {
  @Input() readonly: boolean = false;
  @Input() createBtnText: string;
  @Input() noDataText: string = 'RegisterService::NoConsumables';
  @Input() selectedCategory: ConsumableDto = null;
  @Input() data: PagedResultDto<ConsumableTreeNode> = {
    items: [],
    totalCount: 0,
  };
  @Input() childsOnly: boolean = false;
  @Output() selectedNodeChange = new EventEmitter<ConsumableTreeNode>();
  @Output() selectedCategoryChange = new EventEmitter<ConsumableDto>();
  @Output() addRoot = new EventEmitter<void>();
  @Output() addSubUnit = new EventEmitter<ConsumableTreeNode>();
  expandedKeys: any[] = [];
  constructor(
    public readonly list: ListService,
    public readonly service: ConsumableService,
    private confirmation: ConfirmationService
  ) {}

  onDelete(node: ConsumableTreeNode) {
    this.confirmation
      .warn('RegisterService::DeleteConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => this.service.delete(node.id)),
        catchError(err => {
          return of(null);
        })
      )
      .subscribe(this.list.get);
  }
}
