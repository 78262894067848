import { EnvironmentService, Rest, RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import { serialize } from 'object-to-formdata';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import type {
  AssetActionInput,
  AssetCreateDto,
  AssetDetailsDto,
  AssetDto,
  AssetQuantityDetailsDto,
  AssetReserveActionInput,
  AssetStatusDto,
  AssetsCount,
  AttachTrackerToAssetInput,
  DashboardInfoDto,
  DashboardInfoInput,
  GetAssetsInput,
  GetAssetsQuantityInput,
  GetAssetsTransferHistoryInput,
  IFlespiMeterDto,
  PerformActionsPayload,
} from './models';
import type { LookupDto, LookupRequestDto } from '@proxy/shared/models';
import {
  AssetAvailability,
  AssetType,
  AttributeCreateDto,
  AttributeDto,
  AttributeUpdateDto,
  ComponentStatus,
  GetAttributesInput,
  GetMetersInput,
  MeterCreateDto,
  MeterDto,
  MeterSourceType,
  MeterUpdateDto,
} from '@proxy/register-service/assets';
import { AssetLookupRequestDto, IUserPreferences } from '../shared';
import { LocationAssetCount, LocationDto } from '../locations';
import { Confirmation, ConfirmationService, ToasterService } from '@abp/ng.theme.shared';
import { filter, switchMap } from 'rxjs';
import { TrackerDto, TrackerTypeDto } from '@proxy/register-service/assets';
import { IEventConsumable } from '../consumables';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  apiName = 'RegisterService';
  assetType = AssetType;
  defaultGUID: string = '00000000-0000-0000-0000-000000000000';
  urlPrefix = '/api/register/assets';

  constructor(
    private restService: RestService,
    private environment: EnvironmentService,
    private readonly confirmation: ConfirmationService,
    private readonly toasterService: ToasterService
  ) {}

  create = (input: AssetCreateDto) => {
    const formdata = serialize(input, { indices: true, dotsForObjectNotation: true });
    return this.restService.request<any, AssetDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}`,
        body: formdata,
      },
      { apiName: this.apiName }
    );
  };

  validateLocations = (input: any[]) => {
    return this.restService.request<
      any,
      { id: string; locations: { id: string; fullName: string }[] }[]
    >(
      {
        method: 'POST',
        url: `${this.urlPrefix}/validate-locations`,
        body: input,
      },
      { apiName: this.apiName }
    );
  };

  getConsumptions = (id: string) =>
    this.restService.request<any, IEventConsumable[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/consumptions`,
      },
      { apiName: this.apiName }
    );

  getFlespiMeters = ({
    id,
    hourSource,
    meterSource,
  }: {
    id: string;
    hourSource?: MeterSourceType;
    meterSource: MeterSourceType;
  }) =>
    this.restService.request<any, IFlespiMeterDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/flespi-meters`,
        params: {
          id,
          hourSource,
          meterSource,
        },
      },
      { apiName: this.apiName }
    );

  getPreferences = () =>
    this.restService.request<any, IUserPreferences>(
      {
        method: 'GET',
        url: '/api/register/user-preferences',
      },
      { apiName: this.apiName }
    );

  setPreferences = (body: IUserPreferences) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: '/api/register/user-preferences',
        body,
      },
      { apiName: this.apiName }
    );

  getCostCenter = (id: string) =>
    this.restService.request<any, { name: string }>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/costCentre`,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  get = (id: string) =>
    this.restService.request<any, AssetDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}`,
      },
      { apiName: this.apiName }
    );

  getIds = () =>
    this.restService.request<any, { primaryID: string; id: string }[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/primary-ids`,
      },
      { apiName: this.apiName }
    );

  getList = (input: GetAssetsInput) =>
    this.restService.request<any, PagedResultDto<AssetDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          availability: input.availability,
          available: input.available,
          barcode: input.barcode,
          categoryId: input.categoryId,
          categoryPath: input.categoryPath,
          code: input.code,
          daysOnJobsite: input.daysOnJobsite,
          homeLocationId: input.homeLocationId,
          isActive: input.isActive,
          isFixed: input.isFixed,
          jobsiteLocationId: input.jobsiteLocationId,
          jobsites: input.jobsites,
          locationId: input.locationId,
          locationPath: input.locationPath,
          manufacturerId: input.manufacturerId,
          maxResultCount: input.maxResultCount,
          modelId: input.modelId,
          name: input.name,
          nickname: input.nickname,
          notes: input.notes,
          origCertNumber: input.origCertNumber,
          parentId: input.parentId,
          primaryID: input.primaryID,
          rfid: input.rfid,
          rrp: input.rrp,
          secondaryID: input.secondaryID,
          secondaryLocationId: input.secondaryLocationId,
          siteId: input.siteId,
          skipCount: input.skipCount,
          sorting: input.sorting,
          targetLocationId: input.targetLocationId,
          term: input.term,
          type: input.type,
          unnecessaryHire: input.unnecessaryHire,
        },
      },
      { apiName: this.apiName }
    );

  getLookup = (input: AssetLookupRequestDto) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}`,
        params: {
          filter: input.filter,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
          siteId: input.siteId,
          modelId: input.modelId,
          locationId: input.locationId,
          categoryId: input.categoryId,
          manufacturerId: input.manufacturerId,
          parentId: input.parentId,
        },
      },
      { apiName: this.apiName }
    );

  getQuantityList = (input: GetAssetsInput) =>
    this.restService.request<any, PagedResultDto<AssetQuantityDetailsDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/quantity`,
        params: {
          assetType: input.assetType,
          availability: input.availability,
          availabilityCount: input.availabilityCount,
          available: input.available,
          barcode: input.barcode,
          categoryId: input.categoryId,
          categoryName: input.categoryName,
          categoryPath: input.categoryPath,
          code: input.code,
          count: input.count,
          daysOnJobsite: input.daysOnJobsite,
          hireCount: input.hireCount,
          homeLocationId: input.homeLocationId,
          isActive: input.isActive,
          isFixed: input.isFixed,
          jobsiteLocationId: input.jobsiteLocationId,
          jobsites: input.jobsites,
          locationId: input.locationId,
          locationPath: input.locationPath,
          manufacturerId: input.manufacturerId,
          maxResultCount: input.maxResultCount,
          modelId: input.modelId,
          name: input.name,
          nickname: input.nickname,
          notes: input.notes,
          origCertNumber: input.origCertNumber,
          parentId: input.parentId,
          primaryID: input.primaryID,
          rfid: input.rfid,
          rrp: input.rrp,
          secondaryID: input.secondaryID,
          secondaryLocationId: input.secondaryLocationId,
          siteId: input.siteId,
          skipCount: input.skipCount,
          sorting: input.sorting,
          targetLocationId: input.targetLocationId,
          term: input.term,
          type: input.type,
          unnecessaryHire: input.unnecessaryHire,
        },
      },
      { apiName: this.apiName }
    );

  getOrderNumber = () =>
    this.restService.request<any, { value: string }>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/orderNumber`,
      },
      { apiName: this.apiName }
    );

  getLocationsLookup = (assetId: string) =>
    this.restService.request<any, PagedResultDto<LookupDto<string>>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/lookup/${assetId}/locations`,
      },
      { apiName: this.apiName }
    );

  getDetails = (id: string, isPageTemporary: boolean = false, tenantPassword: string = '') =>
    this.restService.request<any, AssetDetailsDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${isPageTemporary ? 'guest/' : ''}${id}/details`,
        params: {
          tenantPassword: tenantPassword,
        },
      },
      { apiName: this.apiName }
    );

  getAssetTransferHistory = (input: GetAssetsTransferHistoryInput) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/assetTransferHistory`,
        params: {
          assetId: input.id,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  getAssetUtilization = (assetId: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/assetUtilization/${assetId}`,
      },
      { apiName: this.apiName }
    );

  getStatus = (id: string) =>
    this.restService.request<any, AssetStatusDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/status`,
      },
      { apiName: this.apiName }
    );

  update = (id: string, input: AssetCreateDto) => {
    const formData = serialize(input, { indices: true, dotsForObjectNotation: true });

    return this.restService.request<any, AssetDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}`,
        body: formData,
      },
      { apiName: this.apiName }
    );
  };

  updateStatus = (id: string) => {
    return this.restService.request<any, AssetDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/updateIsFixedStatus`,
      },
      { apiName: this.apiName }
    );
  };

  getPicture = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/picture`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  getPictureUrl = (id: string, isPageTemporary: boolean = false, tenantPassword: string = '') =>
    id
      ? `${this.urlPrefix}/${isPageTemporary ? 'guest/' : ''}${id}/picture${
          isPageTemporary ? `?tenantPassword=${tenantPassword}` : ''
        }`
      : null;

  getQRPictureUrl = (id: string) =>
    this.restService.request<any, any>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/qr`,
        responseType: 'blob',
      },
      { apiName: this.apiName }
    );

  deletePicture = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/picture`,
      },
      { apiName: this.apiName }
    );

  addAttribute = (id: string, input: AttributeCreateDto) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/attributes`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteAttribute = (id: string, name: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
      },
      { apiName: this.apiName }
    );

  getAttribute = (id: string, name: string) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
      },
      { apiName: this.apiName }
    );

  getAttributeList = (input: GetAttributesInput) =>
    this.restService.request<any, PagedResultDto<AttributeDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${input.assetId}/attributes`,
        params: {
          term: input.term,
          name: input.name,
          label: input.label,
          readingType: input.valueType,
          value: input.value,
          assetId: input.assetId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  updateAttribute = (id: string, name: string, input: AttributeUpdateDto) =>
    this.restService.request<any, AttributeDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/attributes/${name}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  addMeter = (id: string, input: MeterCreateDto) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/${id}/meters`,
        body: input,
      },
      { apiName: this.apiName }
    );

  deleteMeter = (id: string, name: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
      },
      { apiName: this.apiName }
    );

  getMeter = (id: string, name: string) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
      },
      { apiName: this.apiName }
    );

  getSubAssets = (body: { ids: string[]; pinOrKeyringPassword?: string }) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/sub-assets`,
        body,
      },
      { apiName: this.apiName }
    );

  getMeterList = (input: GetMetersInput) =>
    this.restService.request<any, PagedResultDto<MeterDto>>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/${input.assetId}/meters`,
        params: {
          term: input.term,
          name: input.name,
          label: input.label,
          readingType: input.readingType,
          reading: input.reading,
          startReading: input.startReading,
          assetId: input.assetId,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );

  updateMeter = (id: string, name: string, input: MeterUpdateDto) =>
    this.restService.request<any, MeterDto>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${id}/meters/${name}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getAssetsCountByLocation = (input: GetAssetsInput) =>
    this.restService.request<any, LocationAssetCount>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/assetsCount`,
        params: {
          locationId: input.locationId,
          locationPath: input.locationPath,
        },
      },
      { apiName: this.apiName }
    );

  changeLocationAction = (
    input: AssetActionInput,
    actionType: string,
    pinOrKeyringPassword: string = ''
  ) =>
    this.restService.request<any, void>(
      {
        method: 'PUT',
        url: `${this.urlPrefix}/${actionType}`,
        body: input,
      },
      { apiName: this.apiName }
    );

  reserve = (input: AssetReserveActionInput) =>
    this.restService.request<any, void>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/reserve`,
        body: input,
      },
      { apiName: this.apiName }
    );

  collectAsset(asset: AssetDetailsDto) {
    return this.confirmation
      .warn('RegisterService::CollectConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => {
          return this.performAction({ actionType: 'collect', asset, transferData: null });
        })
      );
  }

  offHire(asset: AssetDetailsDto) {
    return this.confirmation
      .warn('RegisterService::OffHireConfirmationMessage', 'RegisterService::AreYouSure', {
        messageLocalizationParams: [],
      })
      .pipe(
        filter(status => status === Confirmation.Status.confirm),
        switchMap(() => {
          return this.performAction({ actionType: 'offhire', asset, transferData: null });
        })
      );
  }

  getDashboardInfo = (input: DashboardInfoInput) =>
    this.restService.request<any, DashboardInfoDto>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/dashboard`,
        params: {
          deviceId: input.id,
          startTripDate: input.startTripDate,
          endTripDate: input.endTripDate,
        },
      },
      { apiName: this.apiName }
    );

  validateTenantPassword = (id: string, password: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/guest/${id}/checkTenantPassword/${password}`,
      },
      { apiName: this.apiName }
    );

  isTenantPasswordRequired = (id: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/guest/${id}/isRequired`,
      },
      { apiName: this.apiName }
    );

  checkPinOrKeyringPassword = (assetId: string, pinOrKeyringPassword: string) =>
    this.restService.request<any, boolean>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/checkPinOrKeyringPassword/${assetId}/${pinOrKeyringPassword}`,
      },
      { apiName: this.apiName }
    );

  getAssetTypeLetter(asset: AssetDetailsDto) {
    let result: string;
    switch (asset.assetType) {
      case this.assetType.OWNED:
        result = 'O';
        break;

      case this.assetType.HIRED:
        result = 'H';
        break;

      case this.assetType.BORROWED:
        result = 'B';
        break;

      case this.assetType.LEASED:
        result = 'L';
        break;

      case this.assetType.OFFHIRED:
        result = 'OFF';
        break;
    }

    return result;
  }

  attachTrackersToAsset(
    newlyCreatedAsset: AssetDto,
    trackers: TrackerDto[],
    trackerType: TrackerTypeDto
  ) {
    let inputArray = [];

    trackers.forEach(tracker => {
      const input: AttachTrackerToAssetInput = {
        assetId: newlyCreatedAsset.id,
        deviceId: tracker.id,
        deviceName: tracker.name,
        deviceTypeId: trackerType.id,
        flespiAccountId: tracker.flespiAccountId,
        imei: tracker.configuration.ident,
        simid: tracker.configuration.phone,
      };
      inputArray.push(input);
    });

    this.attachTrackers(inputArray).subscribe({
      next: () => {},
    });
  }

  attachTrackers = (input: AttachTrackerToAssetInput[]) =>
    this.restService.request<any, any>(
      {
        method: 'POST',
        url: `${this.urlPrefix}/assignToDevice`,
        body: input,
      },
      { apiName: this.apiName }
    );

  getTrackerTypes = (config?: Partial<Rest.Config>) =>
    this.restService.request<any, TrackerTypeDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/deviceTypes`,
      },
      { apiName: this.apiName, ...config }
    );

  getTrackersByType = (deviceTypeId: number, assetId?: string) =>
    this.restService.request<any, TrackerDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/devices`,
        params: {
          assetId: assetId,
          deviceTypeId: deviceTypeId,
        },
      },
      { apiName: this.apiName }
    );

  getTrackersLookup = (id?: string, includeAllDevices?: boolean) =>
    this.restService.request<any, TrackerDto[]>(
      {
        method: 'GET',
        url: `${this.urlPrefix}/devices`,
        params: {
          assetId: id,
          includeAllDevices: includeAllDevices,
        },
      },
      { apiName: this.apiName }
    );

  removeTracker = (deviceIds: string[]) =>
    this.restService.request<any, TrackerDto[]>(
      {
        method: 'DELETE',
        url: `${this.urlPrefix}/devices`,
        body: { deviceIds: deviceIds },
      },
      { apiName: this.apiName }
    );

  performAction({
    actionType,
    asset,
    assetIds = [],
    transferData = null,
    isActiveSelected,
    isQueue = false,
    parentJobsiteId = '',
    selectedLocation = null,
    selectedLocationsIds,
    pinOrKeyringPassword = '',
  }: PerformActionsPayload) {
    const assetIdsForAction = asset ? [asset.id] : [...assetIds];
    let locationIds: string[] | null;
    let isActive: boolean | null = null;

    switch (actionType) {
      case 'allocate':
        locationIds =
          selectedLocationsIds || (selectedLocation ? [selectedLocation.id] : [parentJobsiteId]);
        break;
      case 'checkOut':
        locationIds = [selectedLocation.id];
        break;
      case 'collect':
        locationIds = [];
        break;
      case 'deallocate':
        locationIds = [selectedLocation.id];
        break;
      case 'move':
        locationIds = [selectedLocation.id];
        break;
      case 'offhire':
        locationIds = [];
        break;
      case 'return':
        locationIds = [];
        break;
      case 'toggleActive':
        locationIds = null;
        isActive = isActiveSelected;
        break;

      default:
        console.error('Invalid action type');
        return;
    }

    const actionParams: AssetActionInput = {
      assetIds: assetIdsForAction,
      locationIds: locationIds,
      isQueue,
      subAssets: transferData?.subAssets || [],
      missingIds: transferData?.missingIds || [],
      notes: transferData?.notes || '',
      componentStatus: transferData?.componentStatus || ComponentStatus.None,
      pinOrKeyringPassword:
        actionType === 'checkOut' || actionType === 'allocate' ? pinOrKeyringPassword : null,
    };

    if (isActiveSelected === undefined) {
      delete actionParams.isActive;
    } else {
      actionParams.isActive = isActiveSelected;
      delete actionParams.locationIds;
    }

    return this.changeLocationAction(actionParams, actionType);
  }
  handleErrorResponse(error: any): void {
    this.confirmation.info(error.error.error.message, 'Asset Creation', {
      cancelText: 'OK',
      hideYesBtn: true,
    });
    return;
  }

  getAssetsCount = (
    assetType: AssetType,
    availability: AssetAvailability,
    nickname: string
  ): Observable<number> =>
    this.restService
      .request<any, { totalCount: number }>(
        {
          method: 'GET',
          url: `${this.urlPrefix}`,
          params: {
            assetType,
            availability,
            nickname,
          },
        },
        { apiName: this.apiName }
      )
      .pipe(map(response => response.totalCount ?? 0));
}
