import type { PagedAndSortedResultRequestDto } from '@abp/ng.core';

export interface ConsumableCreateDto {
  name?: string;
  nickname?: string;
  hasUnitCategory?: boolean;
  picture?: File;
  unit?: number;
  isQtyAssets?: boolean;
  rrpCost?: number;
}

export enum ConsumablePickerTypes {
  MSL = 'MSL',
  TRANSFER = 'TRANSFER',
  QtyAssets = 'QtyAssets',
}

export interface ConsumableDto extends ConsumableCreateDto {
  id?: string;
  parentId?: string;
  fullName?: string;
  totalCount?: number;
  childCount?: number;
  hasPicture?: boolean;
  path?: string;
  pictureUrl?: string;
  mode?: string;
}

export interface IConsumableCounts {
  consumableId: string;
  totalCount: number;
  countsByLocation: ICountsByLocation[];
}

export type AddConsumableCount = {
  locationId: string;
  count: number;
  consumableId: string;
  name?: string;
  locationName?: string;
};

export interface IConsumeInLocation {
  toLocationId: string;
  consumableId: string;
  isQtyAssets?: boolean;
  countByLocations: {
    getFromLocationId: string;
    count: number;
  }[];
}

export interface GetConsumableInput extends PagedAndSortedResultRequestDto {
  term?: string;
  name?: string;
  picture?: string;
  parentId?: string;
  locationId?: string;
  show?: boolean;
  isQtyAssets?: boolean;
  getCounts?: boolean;
  assetId?: string;
  rrpCost?: number;
  pinOrKeyringPassword?: string;
  takeAvailableOnes?: boolean;
}

export interface ICountsByLocation {
  location: {
    id: string;
    name: string;
    fullName?: string;
  };
  count: number;
}

export interface ILocationConsumable {
  consumable: ConsumableDto;
  count: number;
  id: string;
  locationId: string;
  date?: string;
  operatorName?: string;
}

export interface IEventConsumable {
  consumableId: string;
  consumableName: string;
  cost: number;
  count: number;
  inspectionId: string;
  locationId: string;
  locationName?: string;
  event?: string;
  operatorName?: string;
  assetStatusId?: string;
}
