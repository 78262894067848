<abp-modal [(visible)]="isModalOpen" (disappear)="onCancel()">
  <ng-template #abpHeader>
    <h5>
      {{ consumable?.name || '' }}
    </h5>
  </ng-template>

  <ng-template #abpBody>
    <p *ngIf="!isLoading && !currentData?.countsByLocation" class="text-muted">
      {{
        (isQtyAssets ? 'RegisterService::NoQtyAssets' : 'RegisterService::NoConsumables')
          | abpLocalization
      }}
    </p>
    <div class="flex flex-col gap-2.5 content" *ngIf="currentData?.countsByLocation && consumable">
      <div class="flex flex-col gap-1.5">
        <p class="m-0">
          {{ 'ServicingService::TotalAvailability' | abpLocalization }}
        </p>
        <div class="row mx-0 gap-2">
          <div class="col-4 p-0 me-2">
            <input
              type="number"
              min="0"
              [value]="consumable.totalCount || data.totalCount"
              readonly
              class="form-control"
            />
          </div>
          <div class="col p-0">
            <textarea
              id="category-name"
              #categoryName
              class="form-control"
              [value]="consumable.fullName"
              class="form-control resize-none overflow-hidden !bg-[#f7f7f7] p-[11px]"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-1.5">
        <p class="m-0">
          {{ 'RegisterService::Permission:Locations' | abpLocalization }}
        </p>
        <div
          class="row mx-0 gap-1"
          *ngFor="let item of currentData.countsByLocation; let i = index"
        >
          <div class="col-2 p-0">
            <input
              type="number"
              min="0"
              [value]="getDiff(item.location.id, item.count).diff"
              readonly
              class="form-control"
            />
          </div>
          <div class="col-2 p-0 me-2">
            <input
              type="number"
              min="0"
              (input)="
                onChangeCount($event, item.location.id, getDiff(item.location.id, item.count).count)
              "
              [max]="getDiff(item.location.id, item.count).count"
              [value]="item.count"
              class="form-control"
            />
          </div>

          <div class="col p-0">
            <textarea
              id="category-name"
              #categoryName
              [value]="item.location.fullName"
              class="form-control resize-none overflow-hidden !bg-[#f7f7f7] p-[11px]"
              readonly
            ></textarea>
          </div>
        </div>
      </div>
    </div>

    <app-locations-modal
      [(isOpen)]="openLocationModal"
      [isQtyAssets]="isQtyAssets"
      (handleSelect)="onLocationSelect($event)"
      *ngIf="openLocationModal"
    ></app-locations-modal>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
    <button
      type="button"
      class="btn bg-success text-white"
      (click)="onSelect()"
      [disabled]="!getTotal() || isLoading"
    >
      {{ 'ServicingService::Next' | abpLocalization }}
    </button>
  </ng-template>
</abp-modal>
