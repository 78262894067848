import { ListService, PagedResultDto } from '@abp/ng.core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, finalize, Observable, switchMap } from 'rxjs';
import { ConsumableService } from '@proxy/register-service/consumables/consumable.service';
import {
  ConsumableTreeNode,
  mapConsumablesTreeNode,
} from '@/consumables/models/consumable-tree-node.model';
import { LocationService } from '@proxy/register-service/locations';
import {
  AddConsumableCount,
  ConsumableDto,
  ConsumablePickerTypes,
} from '@proxy/register-service/consumables';
import { Subject } from 'rxjs';
import { ToasterService } from '@abp/ng.theme.shared';

@Component({
  selector: 'app-consumable-picker',
  templateUrl: './consumable-picker.component.html',
  styleUrls: ['./consumable-picker.component.scss'],
})
export class ConsumablePickerComponent implements OnInit {
  @Input() type: ConsumablePickerTypes;
  @Input() isModalOpen = false;
  @Input() locationId: string;
  @Input() counts: AddConsumableCount[];
  @Input() selectRequestFn: (body: AddConsumableCount) => Observable<any>;

  @Output() isModalOpenChange = new EventEmitter<boolean>();
  @Output() countsChange = new EventEmitter<AddConsumableCount[]>();
  ConsumablePickerTypes = ConsumablePickerTypes;
  expandedKeys: any[] = [];
  isLoading = false;
  isTransferModalOpen = false;
  quantity: number = null;
  max: number = null;
  selectedConsumable?: ConsumableTreeNode = null;
  data: PagedResultDto<ConsumableTreeNode> = {
    items: [],
    totalCount: 0,
  };

  searchTerm$ = new Subject<string>();

  constructor(
    public readonly list: ListService,
    public readonly service: ConsumableService,
    private toasterService: ToasterService,
    public readonly locationsService: LocationService
  ) {}

  ngOnInit(): void {
    this.searchTerm$
      .pipe(
        debounceTime(250),
        switchMap(term => {
          this.isLoading = true;
          return this.service
            .getList({
              term,
              show: true,
              isQtyAssets: this.type === ConsumablePickerTypes.QtyAssets,
              getCounts: this.type !== ConsumablePickerTypes.MSL,
              locationId: this.type !== ConsumablePickerTypes.MSL ? this.locationId : undefined,
              skipCount: 0,
              maxResultCount: 100,
            })
            .pipe(finalize(() => (this.isLoading = false)));
        })
      )
      .subscribe((list: PagedResultDto<ConsumableDto>) => {
        this.data = new PagedResultDto<ConsumableTreeNode>({
          totalCount: list.totalCount,
          items: mapConsumablesTreeNode(list.items),
        });
      });
    this.searchTerm$.next('');
  }

  onChangeCount(event) {
    const newValue = +event.target.value;

    if ((this.max && newValue > this.max) || newValue < 0) {
      this.quantity = this.quantity === 0 ? null : 0;
      const message =
        newValue < 0
          ? 'Quantity can not be negative'
          : `Value exceeds the maximum allowed limit of ${this.max}`;

      this.toasterService.info(message);
    } else {
      this.quantity = newValue;
    }
  }

  onSelectedNodeChange($event: ConsumableTreeNode) {
    this.quantity = null;
    this.selectedConsumable = $event;
    // if ($event.totalCount) this.max = $event.totalCount;

    // if (this.counts.length) {
    //   const item = this.counts.find(e => e.consumableId === $event.id);
    //   if (item?.count) this.quantity = item.count;
    // }
  }

  onSearchTermChange(term: string) {
    this.searchTerm$.next(term);
  }

  onSelect() {
    this.isLoading = true;
    const existingConsumable = this.counts.find(
      item => item.consumableId === this.selectedConsumable.id
    );
    const count = (existingConsumable?.count || 0) + this.quantity;

    const body: AddConsumableCount = {
      locationId: this.locationId,
      count: this.quantity,
      consumableId: this.selectedConsumable.id,
    };
    if (this.type !== ConsumablePickerTypes.MSL) body.name = this.selectedConsumable.name;

    const request = this.selectRequestFn ? this.selectRequestFn(body) : null;

    if (request) {
      request.pipe(finalize(() => (this.isLoading = false))).subscribe({
        next: () => {
          if (existingConsumable) {
            existingConsumable.count = count;
          } else {
            this.counts = [...this.counts, body];
          }
          this.countsChange.emit(this.counts);
          this.onCancel();
          if (this.type === ConsumablePickerTypes.MSL)
            this.toasterService.success('ServicingService::SuccessfullySaved');
        },
        error: error => this.service.handleErrorResponse(error),
      });
    }
  }

  onCancel() {
    this.selectedConsumable = null;
    this.quantity = null;
    this.isModalOpenChange.emit(false);
  }
}
