<abp-modal [(visible)]="isModalOpen" (disappear)="onCancel()">
  <ng-template #abpHeader>
    <h5>
      {{
        (type === ConsumablePickerTypes.QtyAssets
          ? 'RegisterService::AddQtyAsset'
          : type === ConsumablePickerTypes.MSL
          ? 'RegisterService::MSL'
          : 'RegisterService::AddConsumables'
        ) | abpLocalization
      }}
    </h5>
  </ng-template>

  <ng-template #abpBody>
    <div class="mb-3">
      <input
        (input)="onSearchTermChange($event.target.value)"
        [placeholder]="'AbpUi::Search' | abpLocalization"
        class="form-control"
      />
    </div>
    <app-categories-tree
      [isLoading]="isLoading"
      [data]="data"
      [readonly]="true"
      [childsOnly]="true"
      [isMSL]="type === ConsumablePickerTypes.MSL"
      [(expandedKeys)]="expandedKeys"
      [counts]="counts"
      [isConsumable]="true"
      [selectedNode]="selectedConsumable"
      (selectedNodeChange)="onSelectedNodeChange($event)"
    >
    </app-categories-tree>

    <div class="d-flex gap-2 align-items-center mt-3" *ngIf="selectedConsumable">
      <label for="quantity" class="form-check-label">
        {{ 'RegisterService::AddNewQuantity' | abpLocalization }}:
      </label>
      <input
        type="number"
        min="0"
        (input)="onChangeCount($event)"
        [max]="max"
        [value]="quantity"
        class="form-control w-auto"
      />
    </div>
  </ng-template>

  <ng-template #abpFooter>
    <button
      type="button"
      class="btn bg-success text-white"
      (click)="onSelect()"
      [disabled]="!quantity || isLoading"
    >
      {{
        (type === ConsumablePickerTypes.MSL ? 'RegisterService::Set' : 'RegisterService::Add')
          | abpLocalization
      }}
    </button>

    <button
      *ngIf="type !== ConsumablePickerTypes.MSL && selectedConsumable"
      type="button"
      class="btn bg-primary text-white"
      [ngClass]="{ 'bg-danger': type === ConsumablePickerTypes.QtyAssets }"
      (click)="isTransferModalOpen = true"
      [disabled]="isBusy"
    >
      {{ 'RegisterService::Transfer' | abpLocalization }}/{{
        (type === ConsumablePickerTypes.QtyAssets
          ? 'RegisterService::WriteOff'
          : 'RegisterService::Consume'
        ) | abpLocalization
      }}
    </button>

    <button type="button" class="btn btn-secondary" (click)="onCancel()">
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>
  </ng-template>

  <app-consumables-select
    *ngIf="type !== ConsumablePickerTypes.MSL && isTransferModalOpen"
    (submitAction)="onCancel()"
    [isQtyAssets]="type === ConsumablePickerTypes.QtyAssets"
    [locationId]="locationId"
    [(isModalOpen)]="isTransferModalOpen"
    [(consumable)]="selectedConsumable"
  />
</abp-modal>
