import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent {
  @Input() options: { id: string; label: string }[] = [];
  @Input() selectedOptions: { id: string; label: string }[] = [];
  @Output() selectedOptionsChange = new EventEmitter<{ id: string; label: string }[]>();
  dropdownOpen = false;

  getSelectedItems(): string {
    if (!this.selectedOptions || this.selectedOptions.length === 0) {
      return '';
    }

    return this.selectedOptions.map(option => option.label).join(', ');
  }

  isOptionSelected(option: { id: string; label: string }): boolean {
    return this.selectedOptions.some(selected => selected.id === option.id);
  }

  onOptionChange(option: { id: string; label: string }): void {
    const index = this.selectedOptions.findIndex(selected => selected.id === option.id);
    if (index === -1) {
      this.selectedOptions.push(option);
    } else {
      this.selectedOptions.splice(index, 1);
    }

    this.selectedOptionsChange.emit([...this.selectedOptions]);
  }
}
